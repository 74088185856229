/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import produce, { Draft } from 'immer';

import { logout } from '@actions/session';
import {
  licencesFetchRequest,
  licencesFetchSuccess,
  licencesFetchError,
} from '@actions/subscriptions';

export default handleActions({
  [licencesFetchRequest]: produce((state: Draft<Subscriptions>) => {
    state.requestInProgress = true;
    state.error = '';
  }),
  [licencesFetchSuccess]: produce((state: Draft<Subscriptions>, action: AnyAction) => {
    state.requestInProgress = false;
    state.list = action.payload.list;
  }),
  [licencesFetchError]: produce((state: Draft<Subscriptions>, action: AnyAction) => {
    state.requestInProgress = false;
    state.error = action.payload.error;
  }),
  [logout]: produce(() => ({})),
}, {});
