import React from 'react';
import classNames from 'classnames';

import {
  SUBSCRIPTIONS_PATH,
  EDIT_PROFILE_PATH,
  RELEASENOTES_PATH,
  DASHBOARD_PATH,
  USERS_PATH,
  EULA_PATH,
  // FAQ_PATH,
  // USER_MANUAL_PATH,
} from '@routes';
import ConfigContext from '@context/config';

import NavLink from './link';

import './style.scss';

type Props = {
  isOpen: boolean;
  isAdmin: boolean;
  organisationName: string;
  actions: {
    logout: () => void;
  };
  currentPath: string;
}

const Navigation: React.FC<Props> = (props: Props) => {
  const {
    isOpen, isAdmin, actions, currentPath, organisationName,
  } = props;
  const {
    supportUrl, appUrl, customLinkTemplate = '', customLinkOrgs = [],
  } = React.useContext(ConfigContext);
  
  // Check if we have a custom app link URL based on the organisation name and the list of
  // organisations in the customLinkOrgs array (process.env.CUSTOM_LINK_ORGS)
  const customLinkOrg = customLinkOrgs.find((org) => (organisationName || '').toLowerCase().includes(org));
  const v360Url = customLinkOrg ? customLinkTemplate.replace('%1', customLinkOrg) : appUrl;

  return (
    <nav className={classNames('navigation', { 'navigation--open': isOpen })}>
      <span className="v360r-logo" />
      <div className="section section__top">
        {
          isAdmin && (
            <>
              <NavLink
                path={DASHBOARD_PATH}
                currentPath={currentPath}
                title="Dashboard"
              />
              <span className="separator" />
              <NavLink
                path={USERS_PATH}
                currentPath={currentPath}
                title="Users"
              />
              <span className="separator" />
              <NavLink
                path={SUBSCRIPTIONS_PATH}
                currentPath={currentPath}
                title="Subscription"
                isLastItem
              />
            </>
          )
        }
      </div>
      <div className="section">
        <a
          className="btn-link btn-link__main"
          href={supportUrl}
          target="__blank"
          rel="noopener noreferrer"
        >
          Support
        </a>
        {isAdmin && (
          <>
            <span className="separator" />
            <NavLink
              path={RELEASENOTES_PATH}
              currentPath={currentPath}
              title="Release Notes"
            />
          </>
        )}
        {isAdmin && (
          <>
            <span className="separator" />
            <NavLink
              path={EULA_PATH}
              currentPath={currentPath}
              title="Eula"
            />
          </>
        )}
        <span className="separator" />
        <NavLink
          path={EDIT_PROFILE_PATH}
          currentPath={currentPath}
          title="Edit profile"
        />
        {/*
          <span className="separator" />
          <NavLink path={USER_MANUAL_PATH} currentPath={currentPath} title="User Manual" />
        */}
        {/*
          <span className="separator" />
          <NavLink path={FAQ_PATH} currentPath={currentPath} title="FAQ" />
        */}
        <span className="separator" />
        <button
          type="button"
          className="btn-link btn-link__main btn-link--last"
          onClick={actions.logout}
        >
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Navigation;
