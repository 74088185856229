import React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import Navigation from '../navigation';

import './style.scss';

type Props = {
  isAdmin: false;
  organisationName?: string;
  actions: {
    logout: () => void;
  };
}

const Header: React.FC<Props> = (props: Props) => {
  const { actions, isAdmin, organisationName } = props;
  const location = useLocation();
  const [isNavOpen, setNavOpen] = React.useState(false);

  React.useEffect(() => {
    if (isNavOpen) {
      setNavOpen(false);
    }
  }, [location.pathname]);

  const toggleMenu = () => {
    setNavOpen(!isNavOpen);
  };

  return (
    <header className="header">
      <button
        className={classNames('hamburger', 'hamburger--spin', { 'is-active': isNavOpen })}
        type="button"
        aria-label="menu"
        aria-controls="navigation"
        onClick={toggleMenu}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      <Navigation
        isAdmin={isAdmin}
        isOpen={isNavOpen}
        actions={actions}
        organisationName={organisationName}
        currentPath={location.pathname}
      />
    </header>
  );
};

export default Header;
