import React from 'react';
import classNames from 'classnames';
import { Switch, Route } from 'react-router';

import Header from '@header/containers/root';
import Notifications from '@notifications/containers/root';

import './style.scss';

type RouteProps = {
  path: string;
  exact?: boolean;
  component: React.ComponentType<any>;
}

type Props = {
  routes: RouteProps[];
  isUserAuthenticated?: boolean;
}

const App: React.FC<Props> = ({ routes, isUserAuthenticated }: Props) => (
  <>
    <Notifications />
    <div className={classNames('content-wrapper', { 'content-wrapper__with-header': isUserAuthenticated })}>
      {isUserAuthenticated && <Header />}
      <Switch>
        {routes.map((route: RouteProps) => (
          <Route
            key={`path-${route.path}`}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    </div>
  </>
);

export default App;
