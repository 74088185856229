import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import {
  LICENCES_FETCH_REQUEST,
  licencesFetchSuccess,
  licencesFetchError,
} from '@actions/subscriptions';
import { fetchLicences } from '@services/subscriptions';
import { formatObjectToCamelCase, formatDate } from '@utils/formatters';

const parseApiResponse = (data) => (
  data
    .map((item) => formatObjectToCamelCase(item))
    .map((item) => ({
      ...item,
      createdAt: formatDate(item.createdAt),
      updatedAt: formatDate(item.updatedAt),
      endDate: item.endDate && formatDate(item.endDate),
    }))
);

export function* subscriptionsRequest(): SagaIterator {
  try {
    const { data } = yield call(fetchLicences);
    yield put(licencesFetchSuccess({ list: parseApiResponse(data) }));
  } catch (error) {
    yield put(licencesFetchError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));
  }
}

export function* watchSubscriptionsRequests(): SagaIterator {
  yield takeLatest(LICENCES_FETCH_REQUEST, subscriptionsRequest);
}
