import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { organisationFetchRequest } from '@actions/organisation';
import Loader from '@components/loader';
import Error from '@pages/error/components/root';

type StateProps = {
  id: string;
  loading: boolean;
  error: string;
  isFetched: boolean;
}

export type DispatchProps = {
  actions: {
    organisationFetchRequest: ({ id }) => void;
  };
}

export default function withOrganisation<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> {
  const Component = ({
    id,
    isFetched,
    loading,
    error,
    actions,
    ...rest
  }: StateProps & DispatchProps) => {
    React.useEffect(() => {
      if (!isFetched) {
        actions.organisationFetchRequest({ id });
      }
    }, []);

    if (loading || (!isFetched && !error)) {
      return <Loader message="Loading organisation data..." />;
    }

    if (error) {
      return <Error message="Couldn't retrieve organisation data. Please try again later." />;
    }

    return <WrappedComponent {...rest as P} />;
  };

  const mapStateToProps = (state: State): StateProps => ({
    id: state.session.user.organisation.id,
    loading: state.organisation.requestInProgress,
    error: state.organisation.error,
    isFetched: !!state.organisation.id,
  });

  const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
      ...bindActionCreators({
        organisationFetchRequest,
      }, dispatch),
    },
  });

  return connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(Component);
}
