/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import produce, { Draft } from 'immer';

import { logout } from '@actions/session';
import {
  organisationFetchRequest,
  organisationFetchSuccess,
  organisationFetchError,
  organisationUpdateSuccess,
} from '@actions/organisation';

export default handleActions({
  [organisationFetchRequest]: produce((state: Draft<Organisation>) => {
    state.requestInProgress = true;
    state.error = '';
  }),
  [organisationFetchSuccess]: produce((state: Draft<Organisation>, action: AnyAction) => ({
    ...state,
    requestInProgress: false,
    ...action.payload,
  })),
  [organisationFetchError]: produce((state: Draft<Organisation>, action: AnyAction) => {
    state.requestInProgress = false;
    state.error = action.payload.error;
  }),
  [organisationUpdateSuccess]: produce((state: Draft<Session>, action: AnyAction) => ({
    ...state,
    ...action.payload.values,
  })),
  [logout]: produce(() => ({})),
}, {});
