import { camelCase } from 'lodash';
import { format } from 'date-fns';

import { USER_ROLES } from '@constants';

const ROLES = {
  [USER_ROLES.admin]: 'Admin',
  [USER_ROLES.superadmin]: 'Superadmin',
  [USER_ROLES.facilitator]: 'Facilitator',
  [USER_ROLES.subuser]: 'Subuser',
};

export const formatObjectToCamelCase = (obj: object) => (
  Object.keys(obj).reduce((acc, curr) => ({ ...acc, [camelCase(curr)]: obj[curr] }), {})
);

export const formatUserRoles = (roles: string[]) => (
  roles.map((role) => ROLES[role]).join(', ')
);

export const formatDate = (date: string, dateFormat = 'dd/MM/yyyy') => (
  format(new Date(date), dateFormat)
);

const getLineAddress = (addressLine?: string) => (addressLine ? `, ${addressLine}` : '');

type Address = {
  addressLine1?: string;
  addressLine2?: string;
  addressTown?: string;
  addressPostcode?: string;
  addressCountry?: string;
}

export const formatAddress = (address: Address) => {
  const fields = ['addressLine1', 'addressLine2', 'addressTown', 'addressPostcode', 'addressCountry'];

  if (!address.addressLine1) {
    return '';
  }

  return fields.reduce((acc, curr, currentIndex) => {
    if (currentIndex === 0) {
      return `${acc}${address[curr]}`;
    }

    if (currentIndex === fields.length - 1) {
      return `${acc}, ${address[curr]}`;
    }

    return `${acc}${getLineAddress(address[curr])}`;
  }, '');
};
