import axios from 'axios';

const LOGIN_URL = '/users/token';
const PROFILE_URL = '/users/profile';
const FORGOT_PASSWORD_URL = '/users/forgot-password';
const RESET_PASSWORD_URL = '/users/reset-password';
const UPDATE_PASSWORD_URL = '/profile/change-password';

type LoginValues = {
  username: string;
  password: string;
};

type ForgotPasswordValues = {
  email: string;
};

type ResetPasswordValues = {
  token: string;
  password: string;
};

type UpdatePasswordValues = {
  password: string;
}

export const login = (values: LoginValues): Promise<any> => (
  axios.post(LOGIN_URL, { ...values })
);

export const fetchUserProfile = (): Promise<any> => (
  axios.get(PROFILE_URL)
);

export const sendResetPasswordLink = (values: ForgotPasswordValues): Promise<any> => (
  axios.post(FORGOT_PASSWORD_URL, { ...values })
);

export const resetPassword = (values: ResetPasswordValues): Promise<any> => (
  axios.post(RESET_PASSWORD_URL, { ...values })
);

export const updatePassword = (values: UpdatePasswordValues): Promise<any> => (
  axios.patch(UPDATE_PASSWORD_URL, { ...values })
);
