import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { clearNotification } from '@actions/notifications';
import Notification from '../../components/notification';

type DispatchProps = {
  actions: {
    clearNotification: ({ id }) => void;
  };
}

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    ...bindActionCreators({
      clearNotification,
    }, dispatch),
  },
});

export default connect<null, DispatchProps>(
  null,
  mapDispatchToProps,
)(Notification);
