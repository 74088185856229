import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { matchRoles } from '@utils/roles';
import { USER_ROLES } from '@constants';
import { logout } from '@actions/session';
import Header from '../../components/root';

export type StateProps = {
  isAdmin: boolean;
  organisationName?: string;
};

type DispatchProps = {
  actions: {
    logout: () => void;
  };
}

export const mapStateToProps = (state: State): StateProps => ({
  organisationName: ((state.session.user || {}).organisation || {}).name,
  isAdmin: matchRoles(
    state.session.user ? state.session.user.roles : [],
    [USER_ROLES.admin, USER_ROLES.superadmin, USER_ROLES.subuser],
  ),
});

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: {
    ...bindActionCreators({
      logout,
    }, dispatch),
  },
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
