import React from 'react';

import './style.scss';

type Props = {
  message: string;
};

const Loader: React.FC<Props> = ({ message }: Props) => (
  <div className="loader d-flex flex-column justify-content-center">
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">{message}</span>
    </div>
    <p className="lead">{message}</p>
  </div>
);

export default Loader;
