/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import produce, { Draft } from 'immer';

import { logout } from '@actions/session';
import {
  countriesFetchRequest,
  countriesFetchSuccess,
  countriesFetchError,
} from '@actions/data-providers';

export default handleActions({
  [countriesFetchRequest]: produce((state: Draft<DataProviders>) => {
    state.countries.requestInProgress = true;
    state.countries.error = '';
  }),
  [countriesFetchSuccess]: produce((state: Draft<DataProviders>, action: AnyAction) => {
    state.countries.requestInProgress = false;
    state.countries.list = action.payload.list;
  }),
  [countriesFetchError]: produce((state: Draft<DataProviders>, action: AnyAction) => {
    state.countries.requestInProgress = false;
    state.countries.error = action.payload.error;
  }),
  [logout]: produce(() => ({
    countries: {},
  })),
}, {
  countries: {},
});
