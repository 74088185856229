import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { countriesFetchRequest } from '@actions/data-providers';
import Loader from '@components/loader';
import Error from '@pages/error/components/root';

type StateProps = {
  loading: boolean;
  error: string;
  countriesList: Country[];
}

export type DispatchProps = {
  actions: {
    countriesFetchRequest: () => void;
  };
}

export default function withCountriesList<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> {
  const Component = ({
    loading,
    countriesList,
    actions,
    error,
    ...rest
  }: StateProps & DispatchProps) => {
    React.useEffect(() => {
      if (!countriesList) {
        actions.countriesFetchRequest();
      }
    }, []);

    if (loading || (!countriesList && !error)) {
      return <Loader message="Loading countries list..." />;
    }

    if (error) {
      return <Error message={error} />;
    }

    return <WrappedComponent {...rest as P} />;
  };

  const mapStateToProps = (state: State): StateProps => ({
    loading: state.dataProviders.countries.requestInProgress,
    error: state.dataProviders.countries.error,
    countriesList: state.dataProviders.countries.list,
  });

  const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    actions: {
      ...bindActionCreators({
        countriesFetchRequest,
      }, dispatch),
    },
  });

  return connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(Component);
}
