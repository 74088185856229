import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { uuid } from 'uuidv4';

import {
  LOGIN_REQUEST,
  loginSuccess,
  loginError,
  PROFILE_FETCH_REQUEST,
  profileFetchSuccess,
  profileFetchError,
  PROFILE_UPDATE_REQUEST,
  profileUpdateSuccess,
  profileUpdateError,
  PASSWORD_UPDATE_REQUEST,
  passwordUpdateError,
  PASSWORD_RESET_LINK_REQUEST,
  PASSWORD_RESET_REQUEST,
  logout,
} from '@actions/session';
import {
  login,
  fetchUserProfile,
  sendResetPasswordLink,
  resetPassword,
  updatePassword,
} from '@services/session';
import { updateUserDetails } from '@services/users';
import { parseApiErrors } from '@sagas/users';
import { formatObjectToCamelCase } from '@utils/formatters';
import { addNotification } from '@actions/notifications';
import { DASHBOARD_PATH, LOGIN_PATH } from '@routes';

export function* loginRequest(action: AnyAction): SagaIterator {
  const { values, setSubmitting } = action.payload;

  try {
    const { data } = yield call(login, values);
    yield put(loginSuccess({ ...data }));
  } catch (error) {
    yield put(loginError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
      noRedirect: true,
    }));
    setSubmitting(false);
  }
}

export function* profileRequest(): SagaIterator {
  try {
    const { data } = yield call(fetchUserProfile);
    yield put(profileFetchSuccess({ user: formatObjectToCamelCase(data) }));
  } catch (error) {
    yield put(profileFetchError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));
  }
}

export function* profileUpdateRequest(action: AnyAction): SagaIterator {
  const {
    id,
    values,
    onSuccess,
    onError,
    shouldLogoutOnSuccess,
  } = action.payload;

  try {
    yield call(updateUserDetails, id, values);
    yield put(profileUpdateSuccess({ values }));
    onSuccess();
    if (shouldLogoutOnSuccess) {
      yield put(logout({
        reason: 'You\'ve been logged out due to a username update.',
      }));
    } else {
      yield put(addNotification({
        id: uuid(),
        data: {
          type: 'success',
          message: 'Profile successfully updated!',
          link: {
            text: 'Back to dashboard',
            url: DASHBOARD_PATH,
          },
        },
      }));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ errorMessage, errorsList: parseApiErrors(errorsList) });
    yield put(profileUpdateError({
      error: errorMessage,
      status: error.response?.status,
    }));
  }
}

export function* passwordUpdateRequest(action: AnyAction): SagaIterator {
  const {
    values,
    onSuccess,
    onError,
  } = action.payload;

  try {
    yield call(updatePassword, { password: values.password });
    onSuccess();
    yield put(addNotification({
      id: uuid(),
      data: {
        type: 'success',
        message: 'Password successfully updated!',
        link: {
          text: 'Back to dashboard',
          url: DASHBOARD_PATH,
        },
      },
    }));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ errorMessage, errorsList: parseApiErrors(errorsList) });
    yield put(passwordUpdateError({
      error: errorMessage,
      status: error.response?.status,
    }));
  }
}

export function* resetPasswordLinkRequest(action: AnyAction): SagaIterator {
  const {
    values,
    onSuccess,
    onError,
  } = action.payload;

  try {
    yield call(sendResetPasswordLink, values);
    onSuccess();
    yield put(addNotification({
      id: uuid(),
      data: {
        type: 'success',
        message: 'Reset password link sent! Please check your email.',
        link: {
          text: 'Back to login',
          url: LOGIN_PATH,
        },
      },
    }));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ errorMessage, errorsList: parseApiErrors(errorsList) });
  }
}

export function* passwordResetRequest(action: AnyAction): SagaIterator {
  const {
    token,
    values,
    onSuccess,
    onError,
  } = action.payload;

  try {
    yield call(resetPassword, { token, password: values.password });
    onSuccess();
    yield put(addNotification({
      id: uuid(),
      data: {
        type: 'success',
        message: 'Password was reset! Now you can login using the new password.',
        link: {
          text: 'Back to login',
          url: LOGIN_PATH,
        },
      },
    }));
  } catch (error) {
    const status = error.response?.status;
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ status, errorMessage, errorsList: parseApiErrors(errorsList) });
  }
}

export function* watchSessionRequests(): SagaIterator {
  yield takeLatest(LOGIN_REQUEST, loginRequest);
  yield takeLatest(PROFILE_FETCH_REQUEST, profileRequest);
  yield takeLatest(PROFILE_UPDATE_REQUEST, profileUpdateRequest);
  yield takeLatest(PASSWORD_UPDATE_REQUEST, passwordUpdateRequest);
  yield takeLatest(PASSWORD_RESET_LINK_REQUEST, resetPasswordLinkRequest);
  yield takeLatest(PASSWORD_RESET_REQUEST, passwordResetRequest);
}
