import React from 'react';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  title: string;
  path: string;
  currentPath: string;
  isLastItem?: boolean;
}

const Link: React.FC<Props> = ({
  path,
  title,
  currentPath,
  isLastItem,
}: Props) => (
  path !== currentPath
    ? (
      <RouterLink
        to={path}
        className={classNames(
          'btn-link',
          'btn-link__main',
          { 'btn-link--last': isLastItem },
        )}
      >
        {title}
      </RouterLink>
    )
    : (
      <span
        className={classNames(
          'btn-link',
          'btn-link__main',
          'btn-link--active',
          { 'btn-link--last': isLastItem },
        )}
      >
        {title}
      </span>
    )
);

export default Link;
