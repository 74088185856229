/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';
import produce, { Draft } from 'immer';

import { addNotification, clearNotification } from '@actions/notifications';

export default handleActions({
  [addNotification]: produce((state: Draft<Notifications>, action: AnyAction) => {
    if (!state.list) {
      state.list = {};
    }
    state.list[action.payload.id] = {
      ...action.payload.data,
    };
  }),
  [clearNotification]: produce((state: Draft<Notifications>, action: AnyAction) => {
    delete state.list[action.payload.id];
  }),
}, {});
