import { createAction } from 'redux-actions';

export const USERS_FETCH_REQUEST = 'USERS_FETCH_REQUEST';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_ERROR = 'USERS_FETCH_ERROR';
export const usersFetchRequest: any = createAction(USERS_FETCH_REQUEST);
export const usersFetchSuccess: any = createAction(USERS_FETCH_SUCCESS);
export const usersFetchError: any = createAction(USERS_FETCH_ERROR);

export const USER_DETAILS_UPDATE_REQUEST = 'USER_DETAILS_UPDATE_REQUEST';
export const USER_DETAILS_UPDATE_SUCCESS = 'USER_DETAILS_UPDATE_SUCCESS';
export const USER_DETAILS_UPDATE_ERROR = 'USER_DETAILS_UPDATE_ERROR';
export const userDetailsUpdateRequest: any = createAction(USER_DETAILS_UPDATE_REQUEST);
export const userDetailsUpdateSuccess: any = createAction(USER_DETAILS_UPDATE_SUCCESS);
export const userDetailsUpdateError: any = createAction(USER_DETAILS_UPDATE_ERROR);

export const USER_STATUS_UPDATE_REQUEST = 'USER_STATUS_UPDATE_REQUEST';
export const USER_STATUS_UPDATE_SUCCESS = 'USER_STATUS_UPDATE_SUCCESS';
export const USER_STATUS_UPDATE_ERROR = 'USER_STATUS_UPDATE_ERROR';
export const userStatusUpdateRequest: any = createAction(USER_STATUS_UPDATE_REQUEST);
export const userStatusUpdateSuccess: any = createAction(USER_STATUS_UPDATE_SUCCESS);
export const userStatusUpdateError: any = createAction(USER_STATUS_UPDATE_ERROR);

export const USER_PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST';
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_RESET_ERROR = 'USER_PASSWORD_RESET_ERROR';
export const userPasswordResetRequest: any = createAction(USER_PASSWORD_RESET_REQUEST);
export const userPasswordResetSuccess: any = createAction(USER_PASSWORD_RESET_SUCCESS);
export const userPasswordResetError: any = createAction(USER_PASSWORD_RESET_ERROR);

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR';
export const userRegisterRequest: any = createAction(USER_REGISTER_REQUEST);
export const userRegisterSuccess: any = createAction(USER_REGISTER_SUCCESS);
export const userRegisterError: any = createAction(USER_REGISTER_ERROR);

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';
export const userDeleteRequest: any = createAction(USER_DELETE_REQUEST);
export const userDeleteSuccess: any = createAction(USER_DELETE_SUCCESS);
export const userDeleteError: any = createAction(USER_DELETE_ERROR);
