import React from 'react';
import { connect } from 'react-redux';

type StateProps = {
  isUserAuthenticated: any;
}

export default function withSession<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> {
  const Component = ({ isUserAuthenticated, ...rest }: StateProps) => (
    <WrappedComponent isUserAuthenticated={isUserAuthenticated} {...rest as P} />
  );

  const mapStateToProps = (state: State): StateProps => ({
    isUserAuthenticated: !!state.session?.token,
  });

  return connect<StateProps>(
    mapStateToProps,
  )(Component);
}
