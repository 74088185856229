import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';

import {
  COUNTRIES_FETCH_REQUEST,
  countriesFetchSuccess,
  countriesFetchError,
} from '@actions/data-providers';
import { fetchCountries } from '@services/data-providers';

export function* countriesRequest(): SagaIterator {
  try {
    const { data } = yield call(fetchCountries);
    yield put(countriesFetchSuccess({ list: data }));
  } catch (error) {
    yield put(countriesFetchError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));
  }
}

export function* watchDataProvidersRequests(): SagaIterator {
  yield takeLatest(COUNTRIES_FETCH_REQUEST, countriesRequest);
}
