import { combineReducers } from 'redux';

import session from './session';
import organisation from './organisation';
import users from './users';
import subscriptions from './subscriptions';
import notifications from './notifications';
import dataProviders from './data-providers';

export default combineReducers({
  session,
  organisation,
  users,
  subscriptions,
  notifications,
  dataProviders,
});
