import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { DASHBOARD_PATH, LOGIN_PATH } from '@routes';

type StateProps = {
  isUserAuthenticated: any;
}

export default function withAuthentication<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  isRouteProtected = false,
  isRouteEnabledAfterAuthentication = true,
): React.FC<P> {
  const Component = ({ isUserAuthenticated, ...rest }: StateProps) => {
    if (!isUserAuthenticated && isRouteProtected) {
      return <Redirect to={LOGIN_PATH} />;
    }

    if (isUserAuthenticated && !isRouteEnabledAfterAuthentication) {
      return <Redirect to={DASHBOARD_PATH} />;
    }

    return <WrappedComponent {...rest as P} />;
  };

  const mapStateToProps = (state: State): StateProps => ({
    isUserAuthenticated: !!state.session?.token,
  });

  return connect<StateProps>(
    mapStateToProps,
  )(Component);
}
