import { createAction } from 'redux-actions';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const loginRequest: any = createAction(LOGIN_REQUEST);
export const loginSuccess: any = createAction(LOGIN_SUCCESS);
export const loginError: any = createAction(LOGIN_ERROR);

export const LOGOUT = 'LOGOUT';
export const logout: any = createAction(LOGOUT);

export const PROFILE_FETCH_REQUEST = 'PROFILE_FETCH_REQUEST';
export const PROFILE_FETCH_SUCCESS = 'PROFILE_FETCH_SUCCESS';
export const PROFILE_FETCH_ERROR = 'PROFILE_FETCH_ERROR';
export const profileFetchRequest: any = createAction(PROFILE_FETCH_REQUEST);
export const profileFetchSuccess: any = createAction(PROFILE_FETCH_SUCCESS);
export const profileFetchError: any = createAction(PROFILE_FETCH_ERROR);

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';
export const profileUpdateRequest: any = createAction(PROFILE_UPDATE_REQUEST);
export const profileUpdateSuccess: any = createAction(PROFILE_UPDATE_SUCCESS);
export const profileUpdateError: any = createAction(PROFILE_UPDATE_ERROR);

export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_ERROR = 'PASSWORD_UPDATE_ERROR';
export const passwordUpdateRequest: any = createAction(PASSWORD_UPDATE_REQUEST);
export const passwordUpdateSuccess: any = createAction(PASSWORD_UPDATE_SUCCESS);
export const passwordUpdateError: any = createAction(PASSWORD_UPDATE_ERROR);

export const PASSWORD_RESET_LINK_REQUEST = 'PASSWORD_RESET_LINK_REQUEST';
export const PASSWORD_RESET_LINK_SUCCESS = 'PASSWORD_RESET_LINK_SUCCESS';
export const PASSWORD_RESET_LINK_ERROR = 'PASSWORD_RESET_LINK_ERROR';
export const passwordResetLinkRequest: any = createAction(PASSWORD_RESET_LINK_REQUEST);
export const passwordResetLinkSuccess: any = createAction(PASSWORD_RESET_LINK_SUCCESS);
export const passwordResetLinkError: any = createAction(PASSWORD_RESET_LINK_ERROR);

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const passwordResetRequest: any = createAction(PASSWORD_RESET_REQUEST);
export const passwordResetSuccess: any = createAction(PASSWORD_RESET_SUCCESS);
export const passwordResetError: any = createAction(PASSWORD_RESET_ERROR);
