import React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import { USER_ROLES } from '@constants';
import {
  DASHBOARD_PATH,
  EDIT_ORGANISATION_PATH,
  EDIT_USER_PATH,
  CREATE_USER_PATH,
} from '@routes';
import { matchPaths } from '@utils/routes';
import { matchRoles } from '@utils/roles';

type StateProps = {
  userRoles: string[];
}

type OwnProps = {
  location: {
    pathname: string;
  };
  match: any;
};

export default function withUserRoles<P extends OwnProps>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P & OwnProps> {
  const Component = ({
    userRoles,
    location,
    ...rest
  }: StateProps & OwnProps) => {
    if (!matchRoles(
      userRoles,
      [USER_ROLES.admin, USER_ROLES.superadmin, USER_ROLES.subuser],
    ) && matchPaths(
      location.pathname,
      [EDIT_USER_PATH, EDIT_ORGANISATION_PATH, CREATE_USER_PATH],
    )) {
      return <Redirect to={DASHBOARD_PATH} />;
    }

    return <WrappedComponent location={location} {...rest as P} />;
  };

  const mapStateToProps = (state: State): StateProps => ({
    userRoles: state.session.user.roles,
  });

  return connect<StateProps>(
    mapStateToProps,
  )(Component);
}
