import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
  all,
} from 'redux-saga/effects';
import { uuid } from 'uuidv4';

import { redirect } from '@actions';
import {
  USER_DETAILS_UPDATE_REQUEST,
  userDetailsUpdateSuccess,
  userDetailsUpdateError,
  USER_PASSWORD_RESET_REQUEST,
  userPasswordResetError,
  USERS_FETCH_REQUEST,
  usersFetchSuccess,
  usersFetchError,
  USER_REGISTER_REQUEST,
  userRegisterError,
  USER_STATUS_UPDATE_REQUEST,
  userStatusUpdateSuccess,
  userStatusUpdateError,
  USER_DELETE_REQUEST,
  userDeleteError,
  userDeleteSuccess,
} from '@actions/users';
import {
  updateUserDetails,
  fetchUsers,
  createUser,
  updateUserStatus,
  updateUserPassword,
  deleteUser,
} from '@services/users';
import { USER_ROLES } from '@constants';
import { formatObjectToCamelCase } from '@utils/formatters';
import { addNotification } from '@actions/notifications';
import { USERS_PATH } from '@routes';
import { matchRoles } from '@utils/roles';

export const parseApiErrors = (data) => (
  formatObjectToCamelCase(
    Object.keys(data).reduce((acc, curr) => ({
      ...acc,
      [curr]: (data[curr].errors || []).join('/n'),
    }), {}),
  )
);

const parseUsersList = (data) => (
  Array.isArray(data)
    ? data.reduce((acc, curr) => ({ ...acc, [curr.id]: { ...formatObjectToCamelCase(curr) } }), {})
    : Object.keys(data).reduce((acc, curr) => (
      { ...acc, [data[curr].id]: formatObjectToCamelCase(data[curr]) }
    ), {})
);

export function* usersRequest(action: AnyAction): SagaIterator {
  const { payload: { organisationId, userRoles } } = action;

  try {
    let requests;
    if (matchRoles(userRoles, [USER_ROLES.facilitator])
      || matchRoles(userRoles, [USER_ROLES.subuser])) {
      requests = [
        call(fetchUsers, organisationId, USER_ROLES.facilitator),
      ];
    }

    if (matchRoles(userRoles, [USER_ROLES.admin])) {
      requests = [
        call(fetchUsers, organisationId, USER_ROLES.facilitator),
        call(fetchUsers, organisationId, USER_ROLES.subuser),
      ];
    }

    if (matchRoles(userRoles, [USER_ROLES.superadmin])) {
      requests = [
        call(fetchUsers, organisationId, USER_ROLES.facilitator),
        call(fetchUsers, organisationId, USER_ROLES.subuser),
        call(fetchUsers, organisationId, USER_ROLES.admin),
      ];
    }

    const results = yield all(requests);

    const data = results.reduce((acc, curr) => ({
      ...acc,
      ...parseUsersList(curr.data),
    }), {});

    yield put(usersFetchSuccess({ list: data }));
  } catch (error) {
    yield put(usersFetchError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));
  }
}

export function* userDetailsUpdateRequest(action: AnyAction): SagaIterator {
  const {
    id,
    values,
    onSuccess,
    onError,
  } = action.payload;

  try {
    yield call(updateUserDetails, id, values);
    yield put(userDetailsUpdateSuccess({ id, values }));
    onSuccess();
    yield put(addNotification({
      id: uuid(),
      data: {
        type: 'success',
        message: 'User details successfully updated!',
        link: {
          text: 'Back to users list',
          url: USERS_PATH,
        },
      },
    }));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ errorMessage, errorsList: parseApiErrors(errorsList) });
    yield put(userDetailsUpdateError({
      error: errorMessage,
      status: error.response?.status,
    }));
  }
}

export function* userStatusUpdateRequest(action: AnyAction): SagaIterator {
  const {
    id,
    values,
    onSuccess,
    onError,
  } = action.payload;

  try {
    yield call(updateUserStatus, id, { enabled: values.enabled ? 1 : 0 });
    yield put(userStatusUpdateSuccess({ id, values }));
    onSuccess();
    yield put(addNotification({
      id: uuid(),
      data: {
        type: 'success',
        message: 'User status successfully updated!',
        link: {
          text: 'Back to users list',
          url: USERS_PATH,
        },
      },
    }));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ errorMessage, errorsList: parseApiErrors(errorsList) });
    yield put(userStatusUpdateError({
      error: errorMessage,
      status: error.response?.status,
    }));
  }
}

export function* passwordResetRequest(action: AnyAction): SagaIterator {
  const {
    id,
    values,
    onSuccess,
    onError,
  } = action.payload;

  try {
    yield call(updateUserPassword, id, { password: values.password });
    onSuccess();
    yield put(addNotification({
      id: uuid(),
      data: {
        type: 'success',
        message: 'User password successfully updated!',
        link: {
          text: 'Back to users list',
          url: USERS_PATH,
        },
      },
    }));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ errorMessage, errorsList: parseApiErrors(errorsList) });
    yield put(userPasswordResetError({
      error: errorMessage,
      status: error.response?.status,
    }));
  }
}

export function* addUserRequest(action: AnyAction): SagaIterator {
  const {
    values,
    onSuccess,
    onError,
  } = action.payload;

  try {
    yield call(createUser, values);
    onSuccess();
    yield put(addNotification({
      id: uuid(),
      data: {
        type: 'success',
        message: 'User successfully added!',
      },
    }));
    yield put(redirect({
      redirectPath: `${USERS_PATH}?refresh=true`,
    }));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ errorMessage, errorsList: parseApiErrors(errorsList) });
    yield put(userRegisterError({
      error: errorMessage,
      status: error.response?.status,
    }));
  }
}

export function* deleteUserRequest(action: AnyAction): SagaIterator {
  try {
    // Delete the user from the API
    yield call(deleteUser, action.id);
    // Dispatch a success action
    yield put(userDeleteSuccess(action.id));
    // Add a notification to the user
    yield put(addNotification({
      id: uuid(),
      data: {
        type: 'success',
        message: 'User has been successfully deleted!',
      },
    }));
    yield put(redirect({
      redirectPath: `${USERS_PATH}?refresh=true`,
    }));
  } catch (error) {
    // Dispatch an error action
    yield put(userDeleteError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));

    yield put(addNotification({
      id: uuid(),
      data: {
        type: `The user couldn't be deleted! ${error.response?.status}`,
        message: `${error.message}`,
      },
    }));
  }
}

export function* watchUsersRequests(): SagaIterator {
  yield takeLatest(USERS_FETCH_REQUEST, usersRequest);
  yield takeLatest(USER_DETAILS_UPDATE_REQUEST, userDetailsUpdateRequest);
  yield takeLatest(USER_PASSWORD_RESET_REQUEST, passwordResetRequest);
  yield takeLatest(USER_REGISTER_REQUEST, addUserRequest);
  yield takeLatest(USER_STATUS_UPDATE_REQUEST, userStatusUpdateRequest);
  yield takeLatest(USER_DELETE_REQUEST, deleteUserRequest);
}
