import { fork, all } from 'redux-saga/effects';

import { watchSessionRequests } from './session';
import { watchOrganisationRequests } from './organisation';
import { watchUsersRequests } from './users';
import { watchSubscriptionsRequests } from './subscriptions';
import { watchDataProvidersRequests } from './data-providers';

export default function* () {
  yield all([
    fork(watchSessionRequests),
    fork(watchOrganisationRequests),
    fork(watchUsersRequests),
    fork(watchSubscriptionsRequests),
    fork(watchDataProvidersRequests),
  ]);
}
