import React from 'react';

import Message from '@components/message';

type Props = {
  message: string;
};

const Error: React.FC<Props> = ({ message }: Props) => (
  <div className="page page--authenticated">
    <div className="row">
      <section className="section col-xs-12 col-md-8 col-lg-6">
        <h1 className="title">An unexpected error occurred!</h1>
        <div className="content">
          <Message message={message} type="error" />
        </div>
      </section>
      <div className="logo col-xs-12 col-md-4 col-lg-3 offset-lg-3">
        <span className="cognitas-logo" />
      </div>
    </div>
  </div>
);

export default Error;
