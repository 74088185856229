import React from 'react';
import classNames from 'classnames';

import './style.scss';

type Props = {
  type?: 'success' | 'error';
  message?: string;
  children?: React.ReactNode;
}

const Message: React.FC<Props> = ({ message, type, children }: Props) => (
  <p className={classNames('message', type)}>{message || children}</p>
);

export default Message;
