import { createAction } from 'redux-actions';

export const ORGANISATION_FETCH_REQUEST = 'ORGANISATION_FETCH_REQUEST';
export const ORGANISATION_FETCH_SUCCESS = 'ORGANISATION_FETCH_SUCCESS';
export const ORGANISATION_FETCH_ERROR = 'ORGANISATION_FETCH_ERROR';
export const organisationFetchRequest: any = createAction(ORGANISATION_FETCH_REQUEST);
export const organisationFetchSuccess: any = createAction(ORGANISATION_FETCH_SUCCESS);
export const organisationFetchError: any = createAction(ORGANISATION_FETCH_ERROR);

export const ORGANISATION_UPDATE_REQUEST = 'ORGANISATION_UPDATE_REQUEST';
export const ORGANISATION_UPDATE_SUCCESS = 'ORGANISATION_UPDATE_SUCCESS';
export const ORGANISATION_UPDATE_ERROR = 'ORGANISATION_UPDATE_ERROR';
export const organisationUpdateRequest: any = createAction(ORGANISATION_UPDATE_REQUEST);
export const organisationUpdateSuccess: any = createAction(ORGANISATION_UPDATE_SUCCESS);
export const organisationUpdateError: any = createAction(ORGANISATION_UPDATE_ERROR);
