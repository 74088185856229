import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

type Props = UpdateNotification & {
  id: string;
  actions: {
    clearNotification: ({ id }) => void;
  };
};

const Notification: React.FC<Props> = ({
  id,
  message,
  link,
  actions,
}: Props) => {
  let timeout;

  const clickHandler = () => {
    actions.clearNotification({ id });
  };

  React.useEffect(() => {
    timeout = setTimeout(clickHandler, 5000);

    return () => {
      clearTimeout(timeout);
      timeout = null;
    };
  }, []);

  return (
    <div className="notification col-xs-12 col-sm-10 offset-sm-1 col-lg-6 offset-lg-3 alert" role="alert">
      <div className="content">
        <span>{message}</span>
        {link && <Link className="btn-link" to={link.url}>{link.text}</Link>}
      </div>
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={clickHandler}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default Notification;
