/* eslint-disable no-underscore-dangle */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import initAxios, { setAuthorizationHeader } from '@config/axios';
import createStore from '@store';
import rootSaga from '@sagas';
import '@style/index.scss';
import routes from '@routes';
import App from '@app/components/root';
import { CookiesKeys, getCookie } from '@utils/cookies';
import withSession from '@hoc/with-session';
import ConfigContext from '@context/config';

initAxios({
  baseUrl: process.env.API_URL,
});

const history = createBrowserHistory();

const sessionToken = getCookie(CookiesKeys.sessionToken);
if (sessionToken) {
  setAuthorizationHeader(sessionToken);
}

const initialState = {
  session: {
    token: sessionToken,
  },
};

const store = createStore(initialState, history, process.env.NODE_ENV !== 'production');
store.runSaga(rootSaga);

const AppWrapper = withSession(App);

render(
  <Provider store={store}>
    <Router history={history}>
      <ConfigContext.Provider
        value={{
          supportUrl: process.env.SUPPORT_URL,
          appUrl: process.env.APP_URL,
          eulaDocUrl: process.env.EULA_DOC_URL,
          customEulaOrgs: (process.env.CUSTOM_EULA_ORGS || '').split(','),
          customEulaUrlTemplate: process.env.CUSTOM_EULA_URL_TEMPLATE,
          customLinkOrgs: (process.env.CUSTOM_LINK_ORGS || '').split(','),
          customLinkTemplate: process.env.CUSTOM_LINK_TEMPLATE,
        }}
      >
        <AppWrapper routes={routes} />
      </ConfigContext.Provider>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
