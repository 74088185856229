import { AnyAction, Dispatch } from 'redux';

import { LOGOUT } from '@actions/session';
import { REDIRECT } from '@actions';
import { ACCESS_FORBIDDEN_PATH } from '@routes';

export default () => (next: Dispatch) => (action: AnyAction) => {
  const { type, payload, meta } = action;

  if (type.match(/ERROR/)) {
    if (payload.status === 401 && !payload.noRedirect) {
      return next({
        type: LOGOUT,
        payload: {
          reason: 'You\'ve been logged out due to an unauthorized action or due to long inactivity.',
        },
      });
    }

    if (payload.status === 403) {
      return next({
        type: REDIRECT,
        payload: {
          redirectPath: ACCESS_FORBIDDEN_PATH,
        },
      });
    }

    return next({
      type,
      payload: {
        error: payload.error === 'Network Error' || payload.status === 500
          ? 'Something happened on our end. Please try again later.'
          : payload.error,
      },
      meta,
    });
  }

  return next(action);
};
