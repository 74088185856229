/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import produce, { Draft } from 'immer';

import {
  loginRequest,
  loginSuccess,
  loginError,
  logout,
  profileFetchRequest,
  profileFetchSuccess,
  profileFetchError,
  profileUpdateSuccess,
} from '@actions/session';

export default handleActions({
  [combineActions(loginRequest, profileFetchRequest) as any]:
    produce((state: Draft<Session>) => {
      state.requestInProgress = true;
      state.error = '';
    }),
  [loginSuccess]: produce((state: Draft<Session>, action: AnyAction) => {
    state.requestInProgress = false;
    state.token = action.payload.token;
  }),
  [combineActions(loginError, profileFetchError) as any]:
    produce((state: Draft<Session>, action: AnyAction) => {
      state.requestInProgress = false;
      state.error = action.payload.error;
    }),
  [profileFetchSuccess]: produce((state: Draft<Session>, action: AnyAction) => {
    state.requestInProgress = false;
    state.user = action.payload.user;
  }),
  [profileUpdateSuccess]: produce((state: Draft<Session>, action: AnyAction) => ({
    ...state,
    user: {
      ...state.user,
      ...action.payload.values,
    },
  })),
  [logout]: produce(() => ({})),
}, {});
