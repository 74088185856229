import React from 'react';

import Notification from '../../containers/notification';

import './style.scss';

type Props = {
  list: {
    [id: string]: UpdateNotification;
  };
};

const Notifications: React.FC<Props> = ({ list }: Props) => (
  <div className="notifications">
    <div className="container">
      {Object.keys(list).map((id) => (
        <Notification key={id} id={id} {...list[id]} />
      ))}
    </div>
  </div>
);

export default Notifications;
