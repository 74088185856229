import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import {
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects';
import { uuid } from 'uuidv4';

import {
  ORGANISATION_FETCH_REQUEST,
  organisationFetchSuccess,
  organisationFetchError,
  ORGANISATION_UPDATE_REQUEST,
  organisationUpdateSuccess,
  organisationUpdateError,
} from '@actions/organisation';
import { addNotification } from '@actions/notifications';
import { fetchOrganisation, updateOrganisation } from '@services/organisation';
import { parseApiErrors } from '@sagas/users';
import { DASHBOARD_PATH } from '@routes';
import { formatObjectToCamelCase } from '@utils/formatters';
import { addressCountrySelector } from '@selectors/data-providers';

export function* organisationRequest(action: AnyAction): SagaIterator {
  const { payload } = action;

  try {
    const { data } = yield call(fetchOrganisation, payload.id);
    yield put(organisationFetchSuccess({ ...formatObjectToCamelCase(data) }));
  } catch (error) {
    yield put(organisationFetchError({
      error: error.response?.data?.message || error.message,
      status: error.response?.status,
    }));
  }
}

export function* organisationUpdateRequest(action: AnyAction): SagaIterator {
  const {
    id,
    values,
    onSuccess,
    onError,
  } = action.payload;

  try {
    yield call(updateOrganisation, id, values);
    const addressCountry = yield select(addressCountrySelector(values.addressCountry));
    yield put(organisationUpdateSuccess({ values: { ...values, addressCountry } }));
    onSuccess();
    yield put(addNotification({
      id: uuid(),
      data: {
        type: 'success',
        message: 'Organisation details successfully updated!',
        link: {
          text: 'Back to dashboard',
          url: DASHBOARD_PATH,
        },
      },
    }));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorsList = error.response?.data?.form?.children || {};
    onError({ errorMessage, errorsList: parseApiErrors(errorsList) });
    yield put(organisationUpdateError({
      error: errorMessage,
      status: error.response?.status,
    }));
  }
}

export function* watchOrganisationRequests(): SagaIterator {
  yield takeLatest(ORGANISATION_FETCH_REQUEST, organisationRequest);
  yield takeLatest(ORGANISATION_UPDATE_REQUEST, organisationUpdateRequest);
}
