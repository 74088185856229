import axios from 'axios';

export const ORGANISATIONS_URL = '/organisations';

export const fetchOrganisation = (id): Promise<any> => (
  axios.get(`${ORGANISATIONS_URL}/${id}`)
);

export const updateOrganisation = (id, values): Promise<any> => (
  axios.patch(`${ORGANISATIONS_URL}/${id}`, { ...values })
);
