/* global atob */
import { AnyAction, Dispatch } from 'redux';

import { DASHBOARD_PATH, LOGIN_PATH, FACILITATOR_WELCOME_PATH } from '@routes';
import { USER_ROLES } from '@constants';
import { REDIRECT } from '@actions';
import { LOGIN_SUCCESS, LOGOUT } from '@actions/session';
import { clearCookie, CookiesKeys, setCookie } from '@utils/cookies';
import { setAuthorizationHeader, clearAuthorizationHeader } from '@config/axios';

export default () => (next: Dispatch) => (action: AnyAction) => {
  const { type, payload } = action;

  if (type === LOGIN_SUCCESS) {
    setCookie(CookiesKeys.sessionToken, payload.token);
    setAuthorizationHeader(payload.token);
    next(action);
    // If token roles list contains Facilitator role, redirect to facilitator welcome screen
    try {
      const jwtPayload = JSON.parse(atob(payload.token.split('.')[1]));
      const { roles } = jwtPayload;
      const isFacilitator = roles.length === 1 && roles[0] === USER_ROLES.facilitator;
      if (isFacilitator) {
        return next({
          type: REDIRECT,
          payload: {
            redirectPath: FACILITATOR_WELCOME_PATH,
          },
        });
      }
    } catch (err) {
      // Could not parse JWT payload
    }

    return next({
      type: REDIRECT,
      payload: {
        redirectPath: DASHBOARD_PATH,
      },
    });
  }

  if (type === LOGOUT) {
    clearCookie(CookiesKeys.sessionToken);
    clearAuthorizationHeader();
    next(action);

    return next({
      type: REDIRECT,
      payload: {
        redirectPath: LOGIN_PATH,
      },
    });
  }

  return next(action);
};
