import React from 'react';

type Config = {
  supportUrl?: string;
  appUrl?: string;
  eulaDocUrl?: string;
  customEulaOrgs?: string[];
  customEulaUrlTemplate?: string;
  customLinkOrgs?: string[];
  customLinkTemplate?: string;
}

const ConfigContext = React.createContext<Config>({});

export default ConfigContext;
