import axios from 'axios';

import { USER_ROLES } from '@constants';
import { ORGANISATIONS_URL } from './organisation';

const FACILITATORS_URL = '/facilitators';
const ADMINS_URL = '/admins';
const SUBUSERS_URL = '/sub-users';
const USERS_URL = '/users';
const USER_STATUS_URL = '/users/update-status';
const USER_PASSWORD_URL = '/users/change-password';

const USERS_FECTH_URL = {
  [USER_ROLES.admin]: ADMINS_URL,
  [USER_ROLES.facilitator]: FACILITATORS_URL,
  [USER_ROLES.subuser]: SUBUSERS_URL,
};

export const fetchUsers = (id, role): Promise<any> => (
  axios.get(`${ORGANISATIONS_URL}/${id}${USERS_FECTH_URL[role]}`)
);

export const updateUserDetails = (id, values): Promise<any> => (
  axios.patch(`${USERS_URL}/${id}`, { ...values })
);

export const updateUserPassword = (id, values): Promise<any> => (
  axios.patch(`${USER_PASSWORD_URL}/${id}`, { ...values })
);

export const updateUserStatus = (id, values): Promise<any> => (
  axios.patch(`${USER_STATUS_URL}/${id}`, { ...values })
);

export const createUser = (values): Promise<any> => (
  axios.post(USERS_URL, { ...values })
);

export const deleteUser = (id): Promise<any> => (
  axios.delete(`${USERS_URL}/${id}`)
);
