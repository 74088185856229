/* eslint-disable no-param-reassign */
import { AnyAction } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import produce, { Draft } from 'immer';

import { logout } from '@actions/session';
import {
  usersFetchRequest,
  usersFetchSuccess,
  usersFetchError,
  userDetailsUpdateSuccess,
  userStatusUpdateSuccess,
  userDeleteRequest,
  userDeleteSuccess,
  userDeleteError,
} from '@actions/users';

export default handleActions({
  [usersFetchRequest]: produce((state: Draft<Users>) => {
    state.requestInProgress = true;
    state.error = '';
  }),
  [usersFetchSuccess]: produce((state: Draft<Users>, action: AnyAction) => {
    state.requestInProgress = false;
    state.list = action.payload.list;
  }),
  [usersFetchError]: produce((state: Draft<Users>, action: AnyAction) => {
    state.requestInProgress = false;
    state.error = action.payload.error;
  }),
  [combineActions(userDetailsUpdateSuccess, userStatusUpdateSuccess, userDeleteSuccess) as any]:
    produce((state: Draft<Users>, action: AnyAction) => {
      state.list[action.payload.id] = {
        ...state.list[action.payload.id],
        ...action.payload.values,
      };
    }),
  [userDeleteRequest]: produce((state: Draft<Users>) => {
    state.requestInProgress = true;
    state.error = '';
  }),
  [userDeleteError]: produce((state: Draft<Users>, action: AnyAction) => {
    state.requestInProgress = true;
    state.error = action.payload.error;
  }),
  [logout]: produce(() => ({})),
}, {});
