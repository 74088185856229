import React from 'react';
import { connect } from 'react-redux';

import Notifications from '../../components/root';

type StateProps = {
  list: {
    [id: string]: UpdateNotification;
  };
}

export const NotificationsHOC: React.FC<StateProps> = ({ list }: StateProps) => (
  list && Object.keys(list).length > 0 ? <Notifications list={list} /> : null
);

export const mapStateToProps = (state: State): StateProps => ({
  list: state.notifications.list,
});

export default connect<StateProps>(
  mapStateToProps,
)(NotificationsHOC);
