import React from 'react';

import withAuthentication from '@hoc/with-authentication';
import withUserProfile from '@hoc/with-user-profile';
import withUserRoles from '@hoc/with-user-roles';
import withSuspense from '@hoc/with-suspense';
import withUsers from '@hoc/with-users';
import withSubscriptions from '@hoc/with-subscriptions';
import withOrganisation from '@hoc/with-organisation';
import withCountriesList from '@hoc/with-countries-list';

const Login = React.lazy(() => import('@pages/login/components/root'));
const ForgotPassword = React.lazy(() => import('@pages/forgot-password/components/root'));
const ResetPassword = React.lazy(() => import('@pages/reset-password/components/root'));
const Dashboard = React.lazy(() => import('@pages/dashboard/components/root'));
const FacilitatorWelcome = React.lazy(() => import('@pages/facilitator-welcome/components/root'));
const Users = React.lazy(() => import('@pages/users/containers/root'));
const Subscriptions = React.lazy(() => import('@pages/subscriptions/components/root'));
const EditUser = React.lazy(() => import('@pages/edit-user/containers/root'));
const CreateUser = React.lazy(() => import('@pages/create-user/components/root'));
const EditProfile = React.lazy(() => import('@pages/edit-profile/containers/root'));
const EditOrganisation = React.lazy(() => import('@pages/edit-organisation/containers/root'));
const Eula = React.lazy(() => import('@pages/eula/containers/root'));
const AccessForbidden = React.lazy(() => import('@pages/access-forbidden/components/root'));
const NotFound = React.lazy(() => import('@pages/not-found/components/root'));
const FAQ = React.lazy(() => import('@pages/faq/components/root'));
const UserManual = React.lazy(() => import('@pages/user-manual/components/root'));
const ReleaseNotes = React.lazy(() => import('@pages/release-notes/components/root'));

export const DASHBOARD_PATH = '/';
export const FACILITATOR_WELCOME_PATH = '/welcome';
export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password';
export const EDIT_PROFILE_PATH = '/edit-profile';
export const EDIT_ORGANISATION_PATH = '/edit-organisation';
export const USERS_PATH = '/users';
export const EDIT_USER_BASE_PATH = '/edit-user';
export const EDIT_USER_PATH = '/edit-user/:id';
export const CREATE_USER_PATH = '/register-user';
export const SUBSCRIPTIONS_PATH = '/subscriptions';
export const EULA_PATH = '/eula';
export const ACCESS_FORBIDDEN_PATH = '/access-forbidden';
export const NOT_FOUND_PATH = '/not-found';
export const FAQ_PATH = '/faq';
export const USER_MANUAL_PATH = '/user-manual';
export const RELEASENOTES_PATH = '/release-notes';

export default [
  {
    path: DASHBOARD_PATH,
    exact: true,
    component: withAuthentication(
      withUserProfile(withOrganisation(withSubscriptions(withSuspense(Dashboard)))),
      true,
    ),
  },
  {
    path: FACILITATOR_WELCOME_PATH,
    component: withAuthentication(
      withSuspense(FacilitatorWelcome),
      true,
    ),
  },
  {
    path: LOGIN_PATH,
    component: withAuthentication(
      withSuspense(Login),
      false,
      false,
    ),
  },
  {
    path: FORGOT_PASSWORD_PATH,
    component: withAuthentication(
      withSuspense(ForgotPassword),
      false,
      false,
    ),
  },
  {
    path: RESET_PASSWORD_PATH,
    component: withAuthentication(
      withSuspense(ResetPassword),
      false,
      false,
    ),
  },
  {
    path: EDIT_PROFILE_PATH,
    component: withAuthentication(
      withUserProfile(withSuspense(EditProfile)),
      true,
    ),
  },
  {
    path: EDIT_ORGANISATION_PATH,
    component: withAuthentication(
      withUserProfile(
        withUserRoles(withOrganisation(withCountriesList(withSuspense(EditOrganisation)))),
      ),
      true,
    ),
  },
  {
    path: USERS_PATH,
    component: withAuthentication(
      withUserProfile(withSuspense(withUsers(withSubscriptions(Users)))),
      true,
    ),
  },
  {
    path: SUBSCRIPTIONS_PATH,
    component: withAuthentication(
      withUserProfile(withSuspense(withSubscriptions(Subscriptions))),
      true,
    ),
  },
  {
    path: EDIT_USER_PATH,
    component: withAuthentication(
      withUserProfile(withUserRoles(withSuspense(withUsers(withSubscriptions(EditUser))))),
      true,
    ),
  },
  {
    path: CREATE_USER_PATH,
    component: withAuthentication(
      withUserProfile(withUserRoles(withSuspense(CreateUser))),
      true,
    ),
  },
  {
    path: EULA_PATH,
    component: withAuthentication(
      withUserProfile(withOrganisation(withSuspense(Eula))),
      true,
    ),
  },
  {
    path: FAQ_PATH,
    component: withAuthentication(
      withSuspense(FAQ),
      true,
    ),
  },
  {
    path: USER_MANUAL_PATH,
    component: withAuthentication(
      withSuspense(UserManual),
      true,
    ),
  },
  {
    path: RELEASENOTES_PATH,
    component: withAuthentication(
      withSuspense(ReleaseNotes),
      true,
    ),
  },
  {
    path: ACCESS_FORBIDDEN_PATH,
    component: withSuspense(AccessForbidden),
  },
  {
    path: '*',
    component: withSuspense(NotFound),
  },
];
